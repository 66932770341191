/* eslint-disable indent */
import { defineStore } from 'pinia'
import {
	IssueGetterData,
	RemoteZoneGetterData,
	State,
	TravelZoneGetterData,
} from '@/pinia/courier/type'
import { COURIER } from '@/constants/courier'
import { getCourierList } from '@/api/courier/courier.api'

export interface IZipCodes {
    courierId: string;
    districtCode: number | string;
    provinceCode: number | string;
    subDistrictCode: number | string;
    zipCode: number | string;
}

export const useCourierStore = defineStore('courier', {
	persist: true,
	state: (): State => ({
		version: null,
		remoteZoneCourier: [],
		areasPickUpSPX: [],
		couriers: [],
		// courierList: [],
		travelZoneCourier: [],
		issueZone: [],
	}),
	getters: {
		middleAccountCourierList: (state) => {
			const allowCourierName = [COURIER.JT, COURIER.FLASH, COURIER.EMS]

			return state.couriers.filter((courier) =>
				allowCourierName.includes(courier.name)
			)
		},
		courierList: (state) => {
			let couriers = [...state.couriers]
				// hard remove J&T for normal user
				couriers = couriers.filter(
					(courier) => courier.name !== COURIER.NINJA_VAN
				)
			return couriers
		},
		findRemoteZone:
			(state) =>
			(zipCode: IZipCodes): RemoteZoneGetterData | undefined => {
				const findRemoteZoneFromCourierId: any =
					state.remoteZoneCourier.find((each: any) => {
						return each.courier === zipCode.courierId
					})
				if (!findRemoteZoneFromCourierId) return undefined

				const isRemoteZone = findRemoteZoneFromCourierId.items.some(
					(code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					}
				)
				const addressRemote: any =
					findRemoteZoneFromCourierId.items.find(
						(code: IZipCodes) => {
							return (
								code.provinceCode === zipCode.provinceCode &&
								code.districtCode === zipCode.districtCode &&
								code.subDistrictCode ===
									zipCode.subDistrictCode &&
								code.zipCode === zipCode.zipCode
							)
						}
					)
				if (!isRemoteZone) return undefined

				return {
					remoteFee: findRemoteZoneFromCourierId.remoteFee,
					courier: findRemoteZoneFromCourierId.courier,
					addressRemote: addressRemote,
				}
			},
		findCourierByName() {
			return (courierName: string) => {
				const findCourierByName = this.courierList.find(
					(each: any) => each.name === courierName
				)
				return findCourierByName
			}
		},
		findCourierById() {
			return (courierId: string) => {
				const findCourierById = this.couriers.find(
					(each: any) => each._id?.toString() === courierId?.toString()
				)
				return findCourierById
			}
		},
		findTravelZone:
			(state) =>
			(zipCode: IZipCodes): TravelZoneGetterData | undefined => {
				const findTravelZoneFromCourierId: any =
					state.travelZoneCourier.find((each: any) => {
						return each.courier === zipCode.courierId
					})

				if (!findTravelZoneFromCourierId) return undefined

				const isTravelZone = findTravelZoneFromCourierId.items.some(
					(code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					}
				)

				const addressRemote: any =
					findTravelZoneFromCourierId.items.find(
						(code: IZipCodes) => {
							return (
								code.provinceCode === zipCode.provinceCode &&
								code.districtCode === zipCode.districtCode &&
								code.subDistrictCode ===
									zipCode.subDistrictCode &&
								code.zipCode === zipCode.zipCode
							)
						}
					)
				if (!isTravelZone) return undefined

				return {
					travelFee: findTravelZoneFromCourierId.travelFee,
					courier: findTravelZoneFromCourierId.courier,
					addressRemote: addressRemote,
				}
			},
		findIssueZone:
			(state) =>
			(zipCode: IZipCodes): IssueGetterData | undefined => {
				const findIssueZoneFromCourierId: any = state.issueZone.find(
					(each: any) => {
						return each.courier === zipCode.courierId
					}
				)

				if (!findIssueZoneFromCourierId) return undefined

				const isIssueZone = findIssueZoneFromCourierId.items.some(
					(code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					}
				)

				const addressRemote: any =
					findIssueZoneFromCourierId.items.find((code: IZipCodes) => {
						return (
							code.provinceCode === zipCode.provinceCode &&
							code.districtCode === zipCode.districtCode &&
							code.subDistrictCode === zipCode.subDistrictCode &&
							code.zipCode === zipCode.zipCode
						)
					})

				if (!isIssueZone) return undefined

				const findCourier = state.couriers.find(
					(each) => each._id === findIssueZoneFromCourierId.courier
				)

				return {
					courier: findCourier,
					addressRemote: addressRemote,
				}
			},
	},
	actions: {
		async getCourierList() {
			try {
				const courier = await getCourierList()
				// hardcode temp remove JT from courier
				// this.couriers = courier.filter((each: any) => each.name !== COURIER.JT)
				this.couriers = courier
			} catch (error) {
				return Promise.reject(error)
			}
		},

		mapCourierWithOrder(orders: {data:{order:any,courier:{_id:string}}[]}) {
			if(orders.data?.length){
				return {
					...orders,
					data:orders.data.map((order: { courier: { _id: string } }) => {
						return {
							...order,
							courier: this.courierList.find((_courier) => {
								return _courier?._id?.toString() === (order.courier?._id?.toString() || order.courier?.toString())
							})
						}
					}),
				}
			}else{
				return orders
			}
		},
		mapCouriersWithOrder(orders: {
			data: {
				_id: string
				couriers: {
					_id: string;
					courier: {
						_id: string;
						name: string;
						image: string;
					}
				}[];
			}[];
		}) {
			if (orders.data?.length) {
				return {
					...orders,
					data: orders.data.map((order) => {
						return {
							...order,
							couriers: order.couriers.map((courierData) => {
								const foundCourier = this.courierList.find((_courier:{_id:string}) => _courier?._id?.toString() === courierData?.courier?.toString())
								return {
									...courierData,
									courier: foundCourier
								}
							})
						}
					})
				}
			} else {
				return orders
			}
		}
		
	},
})
