export enum COURIER {
	FLASH = 'Flash',
	KERRY = 'Kerry',
	JT = 'J&T',
	SCG = 'SCG',
	EMS = 'Thailand Post',
	NINJA_VAN = 'Ninja Van',
	FLASH_BULKY = 'Flash Bulky',
	SPX = 'Shopee',
	LAZADA_EXPRESS = 'LEX TH',
	LAZADA_BY_SELLER = 'Lazada การจัดส่งโดยผู้ขาย',
	BEST = 'Best',
	DHL = 'DHL',
	TP = 'TP',
	SPX2 = 'Shopee Express',
}

export type CourierMap = {
	[key in COURIER]?: {
		name: string
		generateLinkTrackingNumber: (trackingNumber: string) => string
		courierId: string
	}
}

export const getCourierId = (courier: COURIER): string | undefined => {
	return courierMap[courier]?.courierId
}

export const courierMap: CourierMap = {
	[COURIER.FLASH]: {
		name: 'flash',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://www.flashexpress.co.th/tracking?se=${trackingNumber}`,
		courierId: '616a5b5a7d33520401450d9f'
	},
	[COURIER.FLASH_BULKY]: {
		name: 'flash bulky',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://www.flashexpress.co.th/tracking?se=${trackingNumber}`,
		courierId: '651126ea1835ad1bf3b2eb04'
	},
	[COURIER.EMS]: {
		name: 'Thailand Post',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://track.thailandpost.co.th/?trackNumber=${trackingNumber}`,
		courierId: '62d8d8b3d119d34431f0324b'
	},
	[COURIER.JT]: {
		name: 'J&T',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://www.jtexpress.co.th/service/track?bills=${trackingNumber}`,
		courierId: '616a5b887d33520401450da3'
	},
	[COURIER.NINJA_VAN]: {
		name: 'Ninja Van',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://www.ninjavan.co/th-th/tracking?id=${trackingNumber}`,
		courierId: '63e4c560c57a1c68c9202d0e'
	},
	[COURIER.KERRY]: {
		name: 'Kerry',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://th.kerryexpress.com/th/track/v2/?track=${trackingNumber}`,
		courierId: '616a5b747d33520401450da1'
	},
	[COURIER.SPX]: {
		name: 'Shopee',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://spx.co.th/track?${trackingNumber}`,
		courierId: '657834199717e74e6991233b'
	},
	[COURIER.BEST]: {
		name: 'Best',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://www.best-inc.co.th/track?bills=${trackingNumber}`,
		courierId: '6556c9615ef6621427ffcd5a'
	},
	[COURIER.DHL]: {
		name: 'DHL',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://ecommerceportal.dhl.com/track/?ref=${trackingNumber}`,
		courierId: '6556c9615ef6621427ffcd5a'
	},
	[COURIER.TP]: {
		name: 'TP',
		generateLinkTrackingNumber: (trackingNumber: string): string =>
			`https://tptrack.info/ecom/dtrack.php?id=d${trackingNumber}&type=a1`,
		courierId: '6556c9615ef6621427ffcd5a'
	},
}

export const COURIER_LIST = [
	{
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_flash.jpeg',
		name: 'Flash',
		_id: '616a5b5a7d33520401450d9f',
	},
]

export type IStatusOrderList = {
	id: number
	label: string
	value: string
}

export const STATUS_ORDER_LIST: Array<IStatusOrderList> = [
	{
		id: 1,
		label: 'ร่าง',
		value: 'DRAFT',
	},
	{
		id: 2,
		label: 'คำสั่งซื้อรอดำเนินการ',
		value: 'PROCESS',
	},
	{
		id: 3,
		label: 'เตรียมตัวจัดส่ง',
		value: 'PREPARE_SEND',
	},
	{
		id: 4,
		label: 'อยู่ระหว่างจัดส่ง',
		value: 'SENT',
	},
	{
		id: 5,
		label: 'จัดส่งสำเร็จ',
		value: 'COMPLETED',
	},
	{
		id: 6,
		label: 'ตีกลับ',
		value: 'REJECT',
	},
	{
		id: 7,
		label: 'ถูกยกเลิก',
		value: 'CANCEL',
	},
	{
		id: 8,
		label: 'อายัด',
		value: 'COD_FREEZE',
	},
	{
		id: 9,
		label: 'แก้ไขปฏิทิน COD',
		value: 'COD_CALENDAR',
	},
	{
		id: 10,
		label: 'พัสดุมีปัญหา',
		value: 'PROBLEM',
	},
]

export const STATUS_COURIER = {
	COD: 'เก็บเงินปลายทาง',
	TRANSFER: 'โอนเงิน',
}
